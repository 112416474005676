import { Box, Grid, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BoatIcon } from "../assets/icons/green-boat-icon.svg";
import { ReactComponent as CartIcon } from "../assets/icons/green-cart-icon.svg";
import { ReactComponent as HospitalIcon } from "../assets/icons/green-hospital-icon.svg";
import { ReactComponent as ShopIcon } from "../assets/icons/green-shop-icon.svg";
import { ReactComponent as Map } from "../assets/images/map.svg";
import { locationList } from "../constants";

const defaultProps = {
  center: {
    lat: 9.548134,
    lng: 100.041472,
  },
  zoom: 14,
};

const NearbySection = () => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState("map");

  const nearbyList = [
    { icon: <ShopIcon />, label: "plan.nearby.shop", distance: "4.1" },
    {
      icon: <HospitalIcon />,
      label: "plan.nearby.hospital",
      distance: "4.1",
    },
    { icon: <CartIcon />, label: "plan.nearby.cart", distance: "18" },
    { icon: <BoatIcon />, label: "plan.nearby.boat", distance: "19" },
  ];

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
      map,
      title: t("plan.title"),
    });
    return marker;
  };

  return (
    <Box id="nearby-section" p={5}>
      <Box textAlign="center">
        <Typography variant="h4" fontWeight={700} mb={2}>
          {t("plan.title")}
        </Typography>
        <Typography fontSize={"1.7vh"}>{t("plan.detail")}</Typography>
      </Box>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={"center"} pt={5}>
        {nearbyList.map((item) => (
          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {item.icon}
            <Typography variant="h6" fontWeight={700}>
              {t(item.label)}
            </Typography>
            <Typography fontSize={"1.6vh"}>
              {item.distance} {t("plan.km")}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={"center"} pt={3}>
        {locationList.map((item) => (
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            textAlign="left"
            width={280}
          >
            <Typography variant="h5" fontWeight={700} mb={3} >
              {t(item.label)}
            </Typography>
            {item.children.map((item) => (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography fontSize={"1.6vh"}>{t(item.label)}</Typography>
                <Typography fontSize={"1.6vh"}>
                  {item.distance} {t("plan.km")}
                </Typography>
              </Box>
            ))}
          </Grid>
        ))}
      </Grid>
      <Box
        py={5}
        pt={8}
        
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container justifyContent={"center"}  direction="row" pb={5}>
          <Typography
            fontWeight={700}
            fontSize={"2vh"}
            px={4}
            sx={{ cursor: "pointer" }}
            color={menu === "map" ? "secondary.main" : "grey.200"}
            borderRight="2px solid #DCBB57"
            onClick={() => setMenu("map")}
          >
            {t("plan.map")}
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"2vh"}
            px={4}
            sx={{ cursor: "pointer" }}
            color={menu === "ggmap" ? "secondary.main" : "grey.200"}
            borderRight="2px solid #DCBB57"
            onClick={() => setMenu("ggmap")}
          >
            {t("plan.ggmap")}
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"2vh"}
            px={4}
            sx={{ cursor: "pointer" }}
            color={menu === "route" ? "secondary.main" : "grey.200"}
            onClick={() => {
              window.open(
                "https://www.google.com/maps/dir/Your+Location/Hillside+Village+Resort,+Koh+Samui,+Ko+Samui+District,+Surat+Thani/",
                "_blank"
              );
            }}
          >
            {t("plan.route")}
          </Typography>
        </Grid>
        {menu === "map" && 
        <Map  width="100%" height="40vw"/>
        }
        {menu === "ggmap" && (
          <div style={{ height: "100vw", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAP_KEY,
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            ></GoogleMapReact>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default NearbySection;
