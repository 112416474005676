import { Box, MenuItem, Select, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { floorList, typeList } from "../constants";

const PlanSection = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    type: typeList[0],
    floor: floorList[0],
  });

  const typeDetail =
    data.type.value === "b"
      ? {
          houseType: "plan.houseType",
          bedroom: 2,
          bathroom: 3,
          garage: 1,
          area: 210,
          withPool: true,
        }
      : {
          houseType: "plan.houseType",
          bedroom: 3,
          bathroom: 3,
          garage: 2,
          area: 310,
          withPool: true,
        };

  const handleTypeChange = (event) => {
    setData({ ...data, type: event.target.value });
  };

  const handleFloorChange = (event) => {
    setData({ ...data, floor: event.target.value });
  };

  return (
    <Box textAlign="center" p={5} bgcolor="#F5F5F5">
      <Typography variant="h5" fontWeight={700}>
        {t("product.plan.title")}
      </Typography>
      <Box display="flex" textAlign="left" justifyContent="center" my={3}>
        <Select
          variant="standard"
          sx={{ width: 400, mr: 5 }}
          size="medium"
          value={data.type}
          onChange={handleTypeChange}
        >
          {typeList.map((item) => (
            <MenuItem value={item}>{t(item.label)}</MenuItem>
          ))}
        </Select>
        <Select
          variant="standard"
          sx={{ width: 400 }}
          size="medium"
          value={data.floor}
          onChange={handleFloorChange}
        >
          {floorList.map((item) => (
            <MenuItem value={item}>{t(item.label)}</MenuItem>
          ))}
        </Select>
      </Box>
      <PlanBoxStyled>
        <TextBoxStyled>
          <Box>
            <Typography variant="h4" fontWeight={700} color="primary.main">
              {t(data.type.label)}
            </Typography>
            <Typography variant="h4" fontWeight={700}>
              {t(data.floor.label)}
            </Typography>
          </Box>
          <Typography fontSize={"2vh"}>
            {t(typeDetail.houseType)} <br />
            {typeDetail.bedroom} {t("plan.bedroom")}
            <br />
            {typeDetail.bathroom} {t("plan.bathroom")}
            <br />
            {typeDetail.garage} {t("plan.garage")}
            <br />
            {t("plan.area", { size: typeDetail.area })}
            <br />
            {t("plan.withPool")}
          </Typography>
        </TextBoxStyled>
        <PlanStyled
          src={require(`../assets/images/type-${data.type.value}-floor-${data.floor.value}.png`)}
        />
      </PlanBoxStyled>
    </Box>
  );
};

export default PlanSection;

const PlanBoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const TextBoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  flexDirection: "column",
  justifyContent: "space-between",
  marginRight: 40,

  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    marginRight: 0,
  },
}));

const PlanStyled = styled("img")(({ theme }) => ({
  width: 700,

  [theme.breakpoints.down("md")]: {
    width: 450,
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
