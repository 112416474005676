import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-icon.svg";
import { ReactComponent as IgIcon } from "../../assets/icons/ig-icon.svg";
import { ReactComponent as LineIcon } from "../../assets/icons/line-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp-icon.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign="center">
      <Box color="common.white" bgcolor="grey.300" px={10} py={4}>
        <Typography mb={2} fontWeight={700}>
          {t("footer.project")}
        </Typography>
        <Typography fontWeight={700} fontSize={"1.5vh"}>{t("footer.projectName")}</Typography>
        <Divider sx={{ height: 2, bgcolor: "primary.light", my: 2 }} />
        <Typography fontWeight={700} fontSize={"1.5vh"}>{t("footer.contact")}</Typography>
        <Typography my={2} fontSize={"1.5vh"}>{t("footer.contactDetail")}</Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" justifyContent="space-between" width={200}>
            <FacebookIcon  onClick={() => {
              window.open(
                "https://www.facebook.com/profile.php?id=100094533893008",
                "_blank"
              );
            }}/>
            <LineIcon onClick={() => {
              window.open(
                "https://lin.ee/Uy75oWV",
                "_blank"
              );
            }}/>
            <IgIcon onClick={() => {
              window.open(
                "https://www.instagram.com/samuihillsidevillage/",
                "_blank"
              );
            }}/>
            {/* <WhatsappIcon /> */}
          </Box>
        </Box>
      </Box>
      <Box bgcolor="grey.500" p={3}>
        <Typography color="common.white">
          COPYRIGHT © 2023 , ICON ASIA MANAGEMENT COMPANY LIMITED ALL RIGHTS RESERVED.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
