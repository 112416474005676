import { Box, Button, Typography, styled, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PoolIcon } from "../assets/icons/pool-icon.svg";
import { ReactComponent as SeaIcon } from "../assets/icons/sea-icon.svg";
import { ReactComponent as ShopIcon } from "../assets/icons/shop-icon.svg";
import House2 from "../assets/images/house-2.jpeg";

const CardProject = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const list = [
    {
      icon: <SeaIcon />,
      label: t("landing.card.nearby.first"),
    },
    {
      icon: <PoolIcon />,
      label: t("landing.card.nearby.second"),
    },
    {
      icon: <ShopIcon />,
      label: t("landing.card.nearby.third"),
    },
  ];

  return (
    <CardStyled>
      <CardImage />
      <Typography fontSize={32} fontWeight={700} mt={5}>
        {t("landing.card.name")}
      </Typography>
      <Typography my={4}>{t("landing.card.detail")}</Typography>
      <Typography fontSize={24} fontWeight={700}>
        {t("landing.card.price")}
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} pt={2} pb={2}>
        {list.map((item, index) => (
          <Grid display={"flex"} item xs={2} sm={4} md={4} key={index}>
            {item.icon}
            <Typography ml={2}>{item.label}</Typography>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          border: "2px solid #DCBB57",
          borderRadius: 16,
          "&:hover": {
            border: "2px solid #DCBB57",
            borderRadius: 16,
          },
        }}
        onClick={() => {
          navigate("/samui-hillside-village");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <Typography>{t("landing.card.more")}</Typography>
      </Button>
    </CardStyled>
  );
};

export default CardProject;

const CardStyled = styled(Box)(({ theme }) => ({
  padding: 16,
  boxShadow: "0px 4px 16px 4px rgba(0, 0, 0, 0.25)",
  maxWidth: 900,
  textAlign: "left",
  borderRadius: 8,

  [theme.breakpoints.down("md")]: {
    maxWidth: 500,
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: 300,
  },
}));

const CardImage = styled(Box)(() => ({
  backgroundImage: `url(${House2})`,
  height: 230,
  width: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
}));
