export const gold = {
    300: '#DCBB57',
    500: '#785714'
}

export const green = {
    500: '#005842',
    700: '#09281F'
}

export const grey = {
    100: '#F8F2E2',
    200: '#9C9C9C',
    300: '#484444',
    500: '#211F1F'
}

export const common = {
    white: '#fff',
    black: '#000'
}