
import { common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { gold, green, grey } from './palette';

export const theme = createTheme({
  palette: {
    primary: {
      main: gold[500],
      light: gold[300]
    },
    secondary: {
      main: green[500],
      dark: green[700]
    },
    grey: {
      100: grey[100],
      200: grey[200],
      300: grey[300],
      500: grey[500]
    },
  },
  typography: {
    fontFamily: 'Kanit'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: 'none',
          fontWeight: 400,

          "&:hover": {
            border: 'none',
          },
        }
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: gold[500],
            color: common.white,
            fontWeight: 600,

            "&:hover": {
              background: gold[500],
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            background: common.white,
            color: common.black,
            border: 'none',

            "&:hover": {
              background: gold[500],
            },
          },
        },
      ],
    },
  }
});