import { AppBar, Box, Button, Typography, styled } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EnglishIcon } from "../../assets/icons/lang/en.svg";
import { ReactComponent as ThaiIcon } from "../../assets/icons/lang/th.svg";

const langOptions = [
  {
    id: "th",
    icon: <ThaiIcon />,
  },
  {
    id: "en",
    icon: <EnglishIcon />,
  },
];

const NavBar = ({ menu, icon, bgColor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(langOptions[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const defaultMenu = [
    {
      onClick: () => {
        navigate("/");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      label: "menu.project",
    },
    {
      onClick: () => {
        const item = document.getElementById("aboutus-section");
        const position = item.offsetTop;

        window.scrollTo({
          top: position - 60,
          behavior: "smooth",
        });
      },
      label: "menu.aboutus",
    },
  ];
  const menuList = menu || defaultMenu;

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChangeLanguage = (lng) => {
    setLanguage(lng);
    i18next.changeLanguage(lng.id);
    handleClose();
  };

  return (
    <AppBarStyled>
      <Box display="flex" position="relative" p={1.5} justifyContent="center">
        <LogoStyled src={require("../../assets/icon-asia-logo.png")} onClick={()=>{
          navigate("/");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}/>
        <Button
          sx={{ width: 125, position: "absolute", right: 0 }}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
          onMouseOver={handleClick}
        >
          {language.icon}
          <Typography ml={1}>{t(`languages.${language.id}`)}</Typography>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          {langOptions
            .filter((option) => option.id !== language.id)
            .map((lng) => (
              <MenuItem onClick={() => handleOnChangeLanguage(lng)}>
                {lng.icon}
                <Typography ml={1}>{t(`languages.${lng.id}`)}</Typography>
              </MenuItem>
            ))}
        </Menu>
      </Box>
      <MenuSection bgColor={bgColor}>
        <IconBox>{icon}</IconBox>
        {menuList.map(({ label, onClick }) => (
          <Button onClick={onClick}>
            <Typography color="common.white" fontWeight="bold">
              {t(label)}
            </Typography>
          </Button>
        ))}
      </MenuSection>
    </AppBarStyled>
  );
};

export default NavBar;

const AppBarStyled = styled(AppBar)(({ theme, bgColor }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: "0px 4px 16px 4px #00000025",
}));

const LogoStyled = styled("img")(() => ({
  height: 28
}));

const MenuSection = styled(Box)(({ bgColor }) => ({
  position: "absolute",
  width: "100%",
  bottom: -52,
  right: -8,
  padding: 8,
  backgroundColor: bgColor ? bgColor : "#48444450",
  display: "flex",
  justifyContent: "center",
}));

const IconBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 16,

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
