import { ThemeProvider } from "@mui/material";
import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import enJson from './lang/en.json';
import thJson from './lang/th.json';
import LandingPage from "./pages/LandingPage";
import ProductPage from "./pages/ProductPage";
import { theme } from "./theme";

i18next.use(initReactI18next).init({
  resources: {
    en: enJson,
    th: thJson
  },
  lng: "th",
  fallbackLng: "th",
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/samui-hillside-village" element={<ProductPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
