import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Box } from "@mui/material";
import "swiper/css";

import "./styled.css";

const Carousel = (props) => {
  return (
    <Box position="relative" my={6} mt={4}>
      <Swiper
        loop
        centeredSlides
        initialSlide={2}
        slidesPerView={2}
        spaceBetween={40}
        className="mySwiper"
      >
        {props.type === "landing"? 
        [...Array(4).keys()].map((item) => (
          <>
            <SwiperSlide style={{ height: "100%" }}>
            
              <img
              src={require(`../assets/images/about-us-${item + 1}.jpeg`)}
              width="100%"
              style={{ borderRadius: 16 }}
            />
            </SwiperSlide>
          </>
        ))
        : 
        [...Array(9).keys()].map((item) => (
          <>
            <SwiperSlide style={{ height: "100%" }}>
            
              <img
              src={require(`../assets/images/asset-${item + 1}.jpeg`)}
              width="100%"
              style={{ borderRadius: 16, "object-fit": "cover", "object-position":"0% 60% /* X then Y */"}}
            />
            </SwiperSlide>
          </>
        ))
        }
        
      </Swiper>
    </Box>
  );
};

export default Carousel;
