import { Box, Button, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardProject from "../components/CardProject";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import Carousel from "../components/Carousel";

const LandingPage = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <NavBar />
      <img src={require("../assets/images/house-3.jpg")} style={{"width":"100%",maxHeight:"100vh", "object-fit": "cover", "object-position":"0% 100% /* X then Y */"}}/>
      <Box
        py={6}
        bgcolor="#F5F5F5"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography fontSize={32} fontWeight={700}>
          {t("landing.project")}
        </Typography>
        <Typography my={3}>{t("landing.detail")}</Typography>
        <Box display="contents">
          <Button variant="contained" sx={{ mb: 4, fontSize: 18 }}>
            {t("landing.newProject")}
          </Button>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CardProject />
          </Box>
        </Box>
      </Box>
      {/* <FirstSection id="aboutus-section">
        <FirstImageStyled src={require("../assets/images/about-us-1.jpeg")} />
        <FirstTextBoxStyled>
          <Typography textAlign="center" variant="h6" mb={1} fontWeight={700}>
            {t("aboutus.first.title")}
          </Typography>
          <Typography>{t("aboutus.first.detail")}</Typography>
        </FirstTextBoxStyled>
      </FirstSection> */}
      {/* <SecondSection>
        <Box>
          <Typography variant="h6" fontWeight={700} mb={3}>
            {t("aboutus.second.title")}
          </Typography>
          <SecondTextBoxStyled>
            <Typography>{t("aboutus.second.detail")}</Typography>
          </SecondTextBoxStyled>
        </Box>
        <SecondImageStyled src={require("../assets/images/about-us-2.jpeg")} />
      </SecondSection> */}
      {/* <ThirdSection>
        <Typography>{t("aboutus.third.detail")}</Typography>
      </ThirdSection>
      <Box display="flex" justifyContent="end">
        <ThirdTextBoxStyled>
          <Typography variant="h6" fontWeight={700} color="common.white">
            {t("aboutus.third.title")}
          </Typography>
        </ThirdTextBoxStyled>
      </Box> */}
      <FirstSection id="aboutus-section">
          <Typography textAlign="center" variant="h6" mb={1} fontWeight={700}>
            {t("aboutus.first.title")}
          </Typography>
          <Typography>{t("aboutus.first.detail")}</Typography>
      </FirstSection>
      <ForthSection>
        {/* <img src={require("../assets/images/about-us-1.jpeg")} width="33%" />
        <img src={require("../assets/images/about-us-3.jpeg")} width="33%" />
        <img src={require("../assets/images/about-us-4.jpeg")} width="33%" /> */}
      </ForthSection>
      <Carousel type="landing"/>

      <Footer />
    </Box>
    
  );
};

export default LandingPage;

const FirstSection = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "64px 0 64px 64px",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: 24,
    alignItems: "flex-end",
  },
}));

const FirstImageStyled = styled("img")(({ theme }) => ({
  height: 400,
  marginRight: 40,
  [theme.breakpoints.down("md")]: {
    marginRight: 0,
    marginBottom: 40,
    width: "100%",
    height: 300,
  },
}));

const FirstTextBoxStyled = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.light}`,
  borderRight: "none",
  padding: "40px",
  height: "fit-content",
  width: 600,

  [theme.breakpoints.down("md")]: {
    marginRight: "-24px",
    width: 500,
  },

  [theme.breakpoints.down("sm")]: {
    marginRight: "-24px",
    width: 250,
  },
}));

const SecondSection = styled(Box)(({ theme }) => ({
  padding: 64,
  position: "relative",
  display: "flex",

  [theme.breakpoints.down("md")]: {
    padding: 24,
    flexDirection: "column",
  },
}));

const SecondImageStyled = styled("img")(({ theme }) => ({
  width: 550,
  height: 300,

  [theme.breakpoints.down("md")]: {
    position: "inherit",
    width: "100%",
    marginBottom: 24,
  },
}));

const SecondTextBoxStyled = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  backgroundColor: theme.palette.grey[100],
  padding: 40,
  marginRight: 100,

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 80px)",
    marginBottom: 20,
  },
}));

const ThirdSection = styled(Box)(({ theme }) => ({
  padding: 20,
  alignSelf: "center",
  [theme.breakpoints.down("md")]: {
    padding: 20,
    paddingBottom: 20,
  },
}));

const ThirdTextBoxStyled = styled(Box)(({ theme }) => ({
  borderRadius: "16px 0 0 16px",
  backgroundColor: theme.palette.primary.main,
  padding: 24,
  width: 700,

  [theme.breakpoints.down("md")]: {
    width: 200,
  },
}));

const ForthSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: 64,

  [theme.breakpoints.down("md")]: {
    padding: 24,
    paddingBottom: 48,
  },
}));
