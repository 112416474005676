import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoSamui } from "../assets/icons/logo-samui.svg";
import Carousel from "../components/Carousel";
import NearbySection from "../components/NearbySection";
import PlanSection from "../components/PlanSection";
import Footer from "../components/footer";
import NavBar from "../components/navbar";

const crypto = require("crypto");
const path = require("path");

const ProductPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const publicKey = process.env.REACT_APP_ENCRYPT_KEY;
      const encrpytedBuffer = crypto.publicEncrypt(
        {
          key: publicKey,
        },
        Buffer.from(JSON.stringify(data))
      );
      const encrpytedMessage = encrpytedBuffer.toString("base64");
      await axios.post("https://api.iconsamui.com/apis/v1/customer", {
        encrpytedMessage,
      });
      setIsSuccess(true);
      reset();
    } catch (error) {
      setIsFailed(true);
      console.error(error);
    }
  };

  const menu = [
    {
      onClick: () => {
        navigate("/");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      label: "menu.main",
    },
    {
      onClick: () => {
        const item = document.getElementById("project-detail");
        const position = item.offsetTop;

        window.scrollTo({
          top: position - 100,
          behavior: "smooth",
        });
      },
      label: "menu.detail",
    },
    {
      onClick: () => {
        const item = document.getElementById("nearby-section");
        const position = item.offsetTop;

        window.scrollTo({
          top: position - 100,
          behavior: "smooth",
        });
      },
      label: "menu.location",
    },
  ];

  const getErrorMessage = (name) => {
    const error = errors?.[name];
    if (!error) return { error: false, helperText: undefined };
    if (error.type === "required") {
      return { error: true, helperText: "Required" };
    }
    return { error: true, helperText: error.message };
  };

  const handleSuccessClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSuccess(false);
  };

  const handleFailedClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsFailed(false);
  };

  return (
    <Box>
      <NavBar menu={menu} icon={<LogoSamui />} bgColor="#09281F" />
      <BoxStyled>
        <Box bgcolor="#15151550" position="absolute" width="100%" height="100%">
          <Box
            position="absolute"
            top="50%"
            left="50%"
            textAlign="center"
            sx={{ transform: "translate(-50%, 0)" }}
          >
            <Typography
              variant="h5"
              color="common.white"
              fontWeight={700}
              mb={2}
            >
              {t("product.title")}
            </Typography>
            <Typography variant="h5" color="common.white" fontWeight={700}>
              {t("product.detail")}
            </Typography>
          </Box>
        </Box>
        <MainImage src={require("../assets/images/house-3.jpg")} />
      </BoxStyled>
      <Box
        bgcolor="secondary.dark"
        py={6}
        px={4}
        display="flex"
        justifyContent="center"
      >
        <Box
          bgcolor="common.white"
          p={4}
          borderRadius={2}
          textAlign="center"
          width={"80vh"}
        >
          <Typography variant="h5" fontWeight={700}>
            {t("product.form.title")}
          </Typography>
          <Typography my={3} fontWeight={700}>
            {t("product.form.detail")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...getErrorMessage("firstname")}
                  {...register("firstname", {
                    required: true,
                  })}
                  label={t("product.form.name")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...getErrorMessage("lastname")}
                  {...register("lastname", { required: true })}
                  label={t("product.form.surname")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...getErrorMessage("email")}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  label={t("product.form.email")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  {...getErrorMessage("tel")}
                  {...register("tel", {
                    required: true,
                    valueAsNumber: true,
                    max: 9999999999,
                  })}
                  label={t("product.form.mobile")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...getErrorMessage("province")}
                  {...register("province", { required: true })}
                  label={t("product.form.province")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...getErrorMessage("country")}
                  {...register("country", { required: true })}
                  label={t("product.form.district")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  {...getErrorMessage("budget")}
                  {...register("budget", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  label={t("product.form.price")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  {...getErrorMessage("more")}
                  {...register("more")}
                  label={t("product.form.more")}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={3}>
              <Button variant="contained" type="submit">
                {t("product.form.submit")}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <Box p={5} id="project-detail">
        <DetailBox>
          <DetailImage src={require("../assets/images/house-4.jpg")} />
          <Box>
            <Typography variant="h5" fontWeight={700} mb={4}>
              {t("product.gallery.title")}
            </Typography>
            <Typography fontSize={"1.6vh"}>
              {t("product.gallery.detail")}
            </Typography>
          </Box>
        </DetailBox>
      </Box>
      <Carousel />
      <PlanSection />
      <NearbySection />
      <Footer />
      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("alert.registerSuccess")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isFailed}
        autoHideDuration={6000}
        onClose={handleFailedClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleFailedClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("alert.registerFailed")}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductPage;

const MainImage = styled("img")(({ theme }) => ({
  height: 800,
  width: "100%",
  "object-fit": "cover", 
  "object-position":"0% 40% /* X then Y */",
  [theme.breakpoints.down("sm")]: {
    height: 400,
  },
}));

const BoxStyled = styled(Box)(({ theme }) => ({
  height: 800,
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    height: 400,
  },
}));

const DetailBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

const DetailImage = styled("img")(({ theme }) => ({
  width: "50%",
  marginRight: 40,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginRight: 0,
    marginBottom: 40,
  },
}));
