export const locationList = [
  {
    label: "plan.location.travel",
    children: [
      {
        label: "plan.location.travel1",
        distance: "6",
      },
      {
        label: "plan.location.travel2",
        distance: "19",
      },
      {
        label: "plan.location.travel3",
        distance: "27",
      },
      {
        label: "plan.location.travel4",
        distance: "4.5",
      },
      {
        label: "plan.location.travel5",
        distance: "13",
      },
      {
        label: "plan.location.travel6",
        distance: "2.5",
      },
    ],
  },
  {
    label: "plan.location.lifestyle",
    children: [
      {
        label: "plan.location.lifestyle1",
        distance: "0.2",
      },
      {
        label: "plan.location.lifestyle2",
        distance: "1.2",
      },
      {
        label: "plan.location.lifestyle3",
        distance: "1.8",
      },
      {
        label: "plan.location.lifestyle4",
        distance: "4.1",
      },
    ],
  },
  {
    label: "plan.location.hospital",
    children: [
      {
        label: "plan.location.hospital1",
        distance: "0.7",
      },
      {
        label: "plan.location.hospital2",
        distance: "1.6",
      },
      {
        label: "plan.location.hospital3",
        distance: "4.1",
      },
      {
        label: "plan.location.hospital4",
        distance: "22",
      },
    ],
  },
];

export const typeList = [
  {
    value: "a",
    label: "product.plan.type.A",
  },
  {
    value: "b",
    label: "product.plan.type.B",
  },
];

export const floorList = [
  {
    value: "1",
    label: "product.plan.floor.1",
  },
  {
    value: "2",
    label: "product.plan.floor.2",
  },
];